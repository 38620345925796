
/*@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;700&family=Fauna+One&display=swap');*/

@tailwind base;
@tailwind components;
@tailwind utilities;


.nav-link{
  border-bottom: solid 2px transparent;
  color: white;
}

.nav-item{
  margin: 16px 0;
}
.resume{
  margin: 16px 0;
  background-color: #96c4c3;
  min-width: 40px;
}

.nav-link:hover{
  transition: 0.7s ease;
  border-color: #96c4c3;
}
.main-green{
  background-color: rgba(150, 196, 195, 0.32);
}
.link-selected{
  transition: 0.7s ease;
  border-color: #96c4c3;
}

.main-x-padding{
  padding: 0 0.9rem;
}

.polygone{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 8px;
  border: 1px solid #383A40;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  width: 2.25rem;
  height: 2.25rem;
}

.icon-red-social{
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: rgb(255, 255, 255);
}





.hamburger{
  display: flex;
}

.hamburger.active .bar:nth-child(2){
  opacity: 0;
}
.hamburger.active .bar:nth-child(1){
  transform: translateY(0.48rem) rotate(45deg);
  transition: 0.3s ease;
}
.hamburger.active .bar:nth-child(3){
  transform: translateY(-0.48rem) rotate(-45deg);
  transition: 0.3s ease;
}
.nav-menu{
  position: absolute;
  right: -100%;
  top: 50px;
  flex-direction: column;
  width: 100%;
  text-align: center;
  transition: 0.3s;
  z-index: 12;
}
.nav-item{
  margin: 16px 0;
}
.nav-menu.active{
  right: 0;
}

.grow-p-7{
  flex-grow: 7;
}
.grow-p-2{
  flex-grow: 2;
}





/*md*/
@media  (min-width: 768px) {
  .bg-img{
    //background-image: url("/src/assets/mupanahmd.jpg");
  }


  .hamburger.active .bar:nth-child(1){
    transform: translateY(0.68rem) rotate(45deg);
  }
  .hamburger.active .bar:nth-child(3){
    transform: translateY(-0.68rem) rotate(-45deg);
  }

}

/*lg*/
@media (min-width: 1024px) {
  
}



*{
  user-select: none;
}

.swal-text-selectable {
  user-select: text; /* Permite seleccionar el texto */
}

.container-img-size{
  width: min(40%,400px);
}

/**/
.card {
  text-align: center;
  border: 1px solid #383A40;
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
  /*max-width: 300px;*/
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.card-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.card-content {
  position: relative;
}

.card-image {
  overflow: hidden;
  border-radius: 10px;
  /*height: 300px;*/
}

.card-image img {
  max-width: 100%;
}

.card-description {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 30%;

  background: linear-gradient(to top, rgba(150, 196, 195, 0.89) 50%, rgba(56, 58, 64, 0.19) 80%);
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  box-sizing: border-box;
  /*white-space: nowrap;*/
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
}



.formbold-main-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px;
}

.formbold-form-wrapper {
  margin: 0 auto;
  max-width: 550px;
  width: 100%;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  display: none;

}

.formbold-form-wrapper.active {
  display: block;
}



.formbold-form-input {
  width: 100%;
  padding: 12px 24px;
  margin-bottom: 10px;
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  background: white;
  font-weight: 500;
  font-size: 16px;
  color: #6b7280;
  outline: none;
  resize: none;
}
.formbold-form-input:focus {
  border-color: #6a64f1;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}

.formbold-btn {
  text-align: center;
  font-size: 16px;
  border-radius: 6px;
  padding: 14px 32px;
  border: none;
  font-weight: 600;
  background-color: #96c4c3;
  color: white;
  cursor: pointer;
}
.formbold-btn:hover {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}

.w-full {
  width: 100%;
}
.formbold-form-header {
  background: #6a64f1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 36px;
  border-radius: 8px 8px 0px 0px;
}
.formbold-form-header h3 {
  font-weight: 700;
  font-size: 20px;
  color: white;
}
.formbold-form-header button {
  color: white;
  background: transparent;
  border: none;
}
.formbold-chatbox-form {
  padding: 32px 36px;
}
.formbold-action-buttons {
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 48px;
}
.formbold-action-btn {
  width: 70px;
  height: 70px;
  background: #6a64f1;
  color: white;
  border-radius: 50%;
  margin-left: 20px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.formbold-action-btn .formbold-cross-icon {
  display: none;
}

.formbold-action-btn.active .formbold-cross-icon {
  display: block;
}

.formbold-action-btn.active .formbold-chat-icon {
  display: none;
}
@media (min-width: 540px) {
  .sm\:w-half {
    width: 50%;
  }
}

.responsive-img-proyect{
  //background-size: contain;
}

.shadow-text {
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 1); /* horizontal-offset vertical-offset blur-radius color */
}

/************************/

@media (min-width: 640px) and (orientation: landscape) {
  .custom-landscape {
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
}

.i18n{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 2.5rem;
  right: 0.45rem;


}
.i18n-button-container{
  display: flex;
  flex-direction: column;
  align-items: center;
/*  width: 10rem;*/
  height: 11rem;
  background-color: #383A40;
}
.button-choose-i18n{
  display: flex;
  justify-content: center;
  font-size: 1.25rem;
  bottom: -1rem;
  position: absolute;
  background-color: #383A40;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;

}
.button-i18n{
  width: calc(100% - 10px);
  border-bottom: 1px solid #949494;
  color: #e0e0e0;
}



.flip-card {
  perspective: 1000px;
  width: 100%;
  height: 100%;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;

}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}



.flip-card-back {
  transform: rotateY(180deg);
}
.bg_img_me{
  background-image: url("./assets/blob.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}









